import React, { Component } from 'react'
import styles from './BackButton.module.css'
import { ReactComponent as ArrowBack } from '../images/arrow-back.svg'
import { withRouter } from 'react-router-dom'

class BackButton extends Component {
  onClick = () => {
    const to = this.props.to
    if (to) {
      this.props.history.push(to)
    } else {
      this.props.history.goBack()
    }
  }

  render() {
    return <ArrowBack onClick={this.onClick} className={styles.backButton} />
  }
}

export default withRouter(BackButton)
