import React from 'react'
import styles from './Dialog.module.css'
// import {Button} from 'reactstrap';

const Dialog = ({ children, onClose }) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.frame} onClick={(e) => e.stopPropagation()}>
        {/* <div className={styles.closeButton}>
          <Button color="primary" onClick={() => onClose()}>X</Button>
        </div> */}
        <div className={styles.body}>
          {children}
          {/* <div className={styles.okButton}>
            <Button color="primary" style={{width: '120px'}} onClick={() => onClose()}>OK</Button>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Dialog
