import React, { Component } from 'react'
import styles from './Notifications.module.css'

class Notification extends Component {
  render() {
    if (!this.props.text) return null

    let color = '#007bff'
    let type = this.props.type
    if (type === 'success') color = 'green'
    if (type === 'failure') color = 'red'

    return (
      <div className={styles.main}>
        <div className={styles.box} style={{ backgroundColor: color }}>
          {this.props.text}
        </div>
      </div>
    )
  }
}

export default Notification
